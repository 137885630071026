import { render, staticRenderFns } from "./NewsLists.vue?vue&type=template&id=8f7128fa&scoped=true&"
import script from "./NewsLists.vue?vue&type=script&lang=js&"
export * from "./NewsLists.vue?vue&type=script&lang=js&"
import style0 from "./NewsLists.vue?vue&type=style&index=0&id=8f7128fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f7128fa",
  null
  
)

export default component.exports