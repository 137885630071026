<template>
	<div class="content">
		<div class="search-bar">
			<input
				type="text"
				v-model="searchText"
				class="search-bar__input"
				aria-label="search"
				placeholder="search"
				@input="debounceUpdate"
				ref="searchInput"
			/>
			<button class="search-bar__submit" @click="focusInput">
				<i class="fa fa-search" aria-hidden="true"></i>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			searchText: "",
			timeout: null,
			waitTime: 800,
		};
	},
	methods: {
		debounceUpdate() {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(this.updateSearchQuery, this.waitTime);
		},
		updateSearchQuery() {
			const sanitizedQuery = this.sanitizeQuery(this.searchText);
			this.$emit("search-query-updated", sanitizedQuery);
		},
		sanitizeQuery(query) {
			// Only allow alphanumeric characters, spaces, and some special characters
			return query.replace(/[^a-zA-Z0-9\s,.-:]/g, "");
		},
		focusInput() {
			this.$refs.searchInput.focus();
		},
	},
};
</script>

<style scoped lang="scss">
.search-bar {
	--searc-color: var(--primary);
	border: 2px solid var(--searc-color);
	align-items: center;
	display: flex;
	--size: 35px;
	border-radius: 200px;
	height: var(--size);
	width: var(--size);
	padding: 3px;
	position: relative;
	transition: width 500ms cubic-bezier(0.18, 0.89, 0.32, 1.05);
	overflow: hidden;
	justify-content: flex-end;

	&__input {
		flex-grow: 1;
		align-items: center;
		font-size: 0.8rem;
		padding: 0 0.5em;
		border: 0;
		background: transparent;
		position: absolute;
		opacity: 0;
		cursor: pointer;
		line-height: calc(var(--size) - 3px);
		&:focus {
			outline: 0;
		}
		margin-right: 21px;
		padding-right: 20px;
	}

	&__submit {
		font-size: 1rem;
		cursor: pointer;
		border: 0;
		border-radius: 50%;
		padding: 0;
		background: grey;
		width: calc(var(--size) - 10px);
		height: calc(var(--size) - 10px);
		// margin-left: auto;
		transition: background 150ms ease-in-out;
	}
	&:focus-within {
		width: 100%;
		.search-bar__input {
			opacity: 1;
			cursor: initial;
			width: calc(100% - var(--size));
		}
		.search-bar__submit {
			background: transparent;
			color: var(--searc-color);
			&:focus,
			&:hover {
				outline: 0;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
			}
		}
	}
}
</style>
