// dateHelper.js

import moment from "moment-timezone";

export const findNextAvailableDate = (dates, timezone) => {
  const today = moment.tz(timezone).format("YYYY-MM-DD");
  return dates.includes(today)
    ? today
    : dates.find((date) => moment(date).isAfter(today)) || null;
};

export const getFormattedTime = (time, timezone) => {
  return moment.utc(time).tz(timezone).format("hh:mm A");
};

export const getFormattedDate = (date) => {
  return moment(String(date)).format('dddd, MMMM D');
};


