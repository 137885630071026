<template>
	<div class="channel-card">
		<img
			v-if="image"
			:src="image"
			:alt="title"
			@click="openUrl(link)"
			class="card-image"
		/>
	</div>
</template>
<script>
export default {
	name: "CardComponent",
	props: {
		image: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			required: true,
		},
		link: {
			type: String,
			required: true,
		},
	},
	methods: {
		openUrl(imageUrl) {
			const imageUrlRoute = this.$router.resolve({ path: imageUrl });
			window.open(imageUrlRoute.href.slice(1), "_blank");
		},
	},
};
</script>

<style scoped>
.channel-card {
	max-width: 25rem;
	min-width: 5rem;
	transition: 0.3s;
	box-shadow: 0px 3px 6px #00000096;
	/* border: 2px solid #bbbbbb; */
	border-radius: 6px;
	opacity: 1;
	aspect-ratio: 16/9;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.channel-card > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	/* filter: grayscale(30%); */
	transition: 0.5s ease;
	cursor: pointer;
	position: absolute;
	left: 0px;
	top: 0px;
}

.channel-card > img:hover {
	transform: scale(1.3);
	/* filter: grayscale(0%); */
}

/* .channel-card-overlay {
	position: absolute;
	z-index: 2;
	height: 100%;
	width: 100%;
} */

.channel-card > .channel-card-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	flex-grow: 1;
	gap: 4rem;
	position: absolute;
	z-index: 3;
	margin: 1rem;
}

.channel-card > .channel-card-content > .channel-card-icon {
	color: white;
	text-shadow: 0px 0px 0.5rem white;
	width: 4vw;
	height: auto;
}

/* Media Query for Devices with Max Width 1204px */
@media (max-width: 768px) {
	.channel-card > .channel-card-content > .channel-card-icon {
		width: 10vw !important;
		height: auto;
	}
}
</style>
