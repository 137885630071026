<template>
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<channels />
			</div>
			<!-- <div class="col-md-12 justify-content-center">
				<channel-location-table />
			</div> -->
		</div>
	</div>
</template>

<script>
import Channels from "../Components/NLSE/Channel/ChannelList.vue";
// import ChannelLocationTable from "../Components/NLSE/Channel/ChannelLocationTable.vue";
export default {
	components: {
		Channels,
		// ChannelLocationTable,
	},
};
</script>
