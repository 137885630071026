<template>
	<section id="home" class="iq-main-slider p-0" v-if="homeSliders.length > 0">
		<Slick
			ref="slick"
			@init="navAnimateButton"
			@reinit="navAnimateButton"
			@afterChange="handleAfterChange"
			@beforeChange="handleBeforeChange"
			id="home-slider"
			class="slider m-0 p-0"
			:option="homeSliderOption"
		>
			<div v-for="(data, index) in homeSliders" :key="index">
				<a
					v-if="!data?.videoHlsUrl"
					:href="data.extraURL || undefined"
					target="_blank"
				>
					<div
						:style="`background: url(${data.image}); aspect-ratio: 16/9; width:100%; background-size: cover;    min-height: 20vh; background-position: top; background-repeat: no-repeat; position: relative; z-index: 1;`"
					></div>
				</a>
				<div v-else class="video-player-container">
					<a
						
						:href="data.extraURL || undefined"
						target="_blank"
						id="video-link"
						@touchend="handleVideoRedirection(data.extraURL)"
					>
						<video
							:id="`videoPlayer-${index}`"
							class="video-js video-bg-style"
							preload="metadata"
							data-setup='{}'
							muted
							playsinline
							:poster="data.image"
							isMuted
						></video>
					</a>
					<MuteButton 
						@is-muted="handleMuteAndUnmute" 
						@mute-button-index="muteThisVideo"
						:index="index"
						:id="`muteButton-${index}`"
					/>
				</div>
			</div>
		</Slick>
		<!-- <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
			<symbol
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 44 44"
				width="44px"
				height="44px"
				id="circle"
				fill="none"
				stroke="currentColor"
			>
				<circle r="20" cy="22" cx="22" id="test"></circle>
			</symbol>
		</svg> -->
	</section>
</template>
<script>
import { fetchSliderItems } from "@/services/slider.js"; // Import the fetchSliderItems function
import Video from "video.js";
import "video.js/dist/video-js.css";
import MuteButton from "./MuteButton.vue"

export default {
	name: "Home",
	props: ["categoryType"],
	components: {
		MuteButton,
	},
	data() {
		return {
			homeSliders: [],
			isMuted: true,
			isVideo: {},
			videoSpeed: null,
			imageSpeed: 3000, //default img autoplay speed
			currentSlide: null,
			bannerStyle:
				"background: url(${bgImage}); width:100%; background-size: cover; background-position: 60% center; background-repeat: no-repeat; min-height: calc(100vh - 40px); position: relative; z-index: 1;",
			homeSliderOption: {
				autoplay: false,
				// adaptiveHeight: false,
				speed: 1500,
				autoplaySpeed: 3000, //default
				lazyLoad: "progressive",
				centerMode: false,
				arrows: true,
				dots: true,
				prevArrow:
					'<div class="slick-nav prev-arrow"><i></i><svg><use xlink:href="#"></svg></div>',
				nextArrow:
					'<div class="slick-nav next-arrow"><i></i><svg><use xlink:href="#"></svg></div>',
				// responsive: [
				//   {
				//     breakpoint: 1024,
				//     settings: {
				//       dots: true,
				//       arrows: false
				//     }
				//   }
				// ]
			},
		};
	},
	mounted() {
		const slider = this.$refs.slick;
	},
	beforeUpdate() {
		if (this.$refs.slick) {
			this.$refs.slick.destroy();
		}
	},
	updated() {
		this.$nextTick(function () {
			if (this.$refs.slick) {
				this.$refs.slick.create(this.homeSliderOption);
			}
		});
	},
	methods: {
		// Video Methods
		initVideoJS(index, url) {
			const videoId = `videoPlayer-${index}`;
			const player = Video(videoId);

			player.on("loadedmetadata", () => {
				console.log("Video metadata has been loaded, ready to play");
				// console.log('player', player)

				//get initial video to play and pause all others
				if (index === 0) {
					player
						.play()
						.then(() => {
							console.log("Promise resolved. Playback started.");
						})
						.catch((error) => {
							console.error("Promise rejected. Playback failed:", error);
							// More diagnostic information
							console.error("User Agent:", navigator.userAgent);
							console.error("Video attributes: ", player.el().attributes);
						});
				} else {
					player.pause();
					console.log("Video " + index + " has been initially paused.");
				}
			});
			player.on("ended", () => {
				//Enters this conditional only for the first video that plays on load
				if (this.$refs.slick && this.currentSlide === null || this.videoDuration === null) {
					//Force the slider to move on to the next slide after slight pause, and begin autoplay
					//Must be done this way as editing the autoplay property in this.homeSliderOptions object is not reactive enough
					setTimeout(() => {
						this.$refs.slick.next();
						this.$refs.slick.play();
					}, 2000);
				}
			});

			player.on("error", () => {
				console.error("Playback error:", error);
			});

			player.src({ src: url, type: "application/x-mpegURL" });
		},

		//Directs banner video element to NLSE+ homepage if no extraURL exists
		handleVideoRedirection(extraURL) {
			if (extraURL) window.open(extraURL, "_blank");
		},
		pauseCurrentVideo(index) {
			const videoId = `videoPlayer-${index}`;
			const videoElement = document.getElementById(videoId);
			const player = Video(videoElement);
			player.pause();
		},
		playCurrentVideo(index) {
			const videoId = `videoPlayer-${index}`;
			const videoElement = document.getElementById(videoId);
			const player = Video(videoElement);

			player
				.play()
				.then(() => {
					console.log("playing video ", index);
				})
				.catch((error) => {
					console.error("Error playing video " + index, error);
				});
		},
		//Using the index of the current slide, determines whether a video is paused or played
		handleVideoPauseAndPlay(index) {
			//If the current slide is an image, pause all running videos
			if(!this.isVideo[index]){
				for (const key in this.isVideo){
					if(this.isVideo[key]) this.pauseCurrentVideo(Number(key));
				}
			}
			//Check currentSlide index parameter against isVideo obj
			if (this.isVideo[index] && index === this.currentSlide) {
				this.playCurrentVideo(index);
			} 
			//Pause all other videos that are playing but not currently displaying on the slide
			for (const key in this.isVideo){
				if(Number(key) !== index && this.isVideo[key]){
					// console.log('Number(key)', Number(key), index)  
					this.pauseCurrentVideo(Number(key));
				}
			}
		},
		handleMuteAndUnmute(data){
			this.isMuted = data;
		},
		muteThisVideo(index){
			const videoId = `videoPlayer-${index}`;
			const videoElement = document.getElementById(videoId);
			const player = Video(videoElement);
			if(this.isMuted){
				player.muted(true)
			}
			if(!this.isMuted){
				player.muted(false)
			}
		},
		// Slider methods
		navAnimateButton(event, slider) {
			console.log("reinit navanimatebutton");
			const nav = document.getElementsByClassName("slick-nav");
			Array.from(nav, (elem) => {
				if (elem) {
					elem.addEventListener("click", (e) => {
						elem.classList.add("animate");
						setTimeout(() => {
							if (elem) {
								elem.classList.remove("animate");
							}
						}, 1600);
					});
				}
			});
		},
		//vary autoplaySpeed based on whether item is vid or img
		handleAfterChange(payload) {
			//Match the currentSlide number to the index of the slider item:
			this.currentSlide = payload.target.slick.currentSlide;
			//If the currentSlide number matches the key in isVideo object, and the item itself is a video:
			if (this.isVideo[this.currentSlide] === true) {
				//Dynamically pull video duration and convert to ms
				this.handleVideoSpeed(this.currentSlide);
				payload.target.slick.options.autoplaySpeed = this.videoSpeed || 6000;
				//Pause all other videos that are not on the currentSlide
				this.handleVideoPauseAndPlay(this.currentSlide);
			} else {
				payload.target.slick.options.autoplaySpeed = this.imageSpeed;
			}
		},
		handleBeforeChange(payload){
			//If the video is unmuted right before the slide changes,
			if(this.isMuted === false){
				console.log('This video needs to be muted')
				this.handleMuteAndUnmute(true);
				this.pauseCurrentVideo(this.currentSlide);

				if(this.currentSlide === null){
					this.currentSlide = payload.target.slick.currentSlide;
					console.log('currentslide in beforechange', this.currentSlide)
				}
			}
			//If this is the initial slide upon mount, force the slider to run to next slide
			if(this.currentSlide === null || this.videoDuration === null){
				this.$refs.slick.next();
				this.$refs.slick.play();
			}
		},
		//Creates obj with each index corresponding to a bool for whether the item is a vid or img
		handleIsVideo(sliderItems) {
			const sliderObj = {};
			sliderItems.forEach((item, index) => {
				if (item?.videoHlsUrl) {
					sliderObj[index] = true;
				} else {
					sliderObj[index] = false;
				}
			});
			return sliderObj;
		},
		//For every video after the first, determines the autoplay speed based on that video's duration
		handleVideoSpeed(index) {
			this.videoSpeed =
				Math.ceil(this.homeSliders[index].videoDuration + 2) * 1000;
		},
	},

	async mounted() {
		try {
			// Fetch all slider items of a particular category, if specified
			const sliderItems = await fetchSliderItems(this.categoryType);
			this.homeSliders = sliderItems;
			if (this.homeSliders.length > 0) {
				this.$emit("update:bannerStatus", true);
			} else {
				this.$emit("update:bannerStatus", false);
			}
			console.log("slider items", this.homeSliders);
			// Set initial video item autoplay speed
			if (this.homeSliders[0].videoDuration) {
				this.homeSliderOption.autoplaySpeed =
					(this.homeSliders[0].videoDuration + 2) * 1000;
			}
			// Create obj this.isVideo to track which items are videos
			this.isVideo = this.handleIsVideo(this.homeSliders);

			// Ensuring DOM updates are complete
			this.$nextTick(() => {
				this.homeSliders.forEach((data, index) => {
					if (data?.videoHlsUrl) {
						this.initVideoJS(index, data.videoHlsUrl);

					}
				});
			});
			this.$emit("loaded");
		} catch (error) {
			console.error(
				"An error occurred while initializing slider items:",
				error
			);
		}
	},
};
</script>
<style>
.button {
	background-color: rgb(235, 186, 10);
	border: none;
	color: white;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	margin: 2px 1px;
	border-radius: 5px;
}

.cn {
	display: flex;
	justify-content: center;
}

#home-slider .slick-dots li.slick-active button {
	background: #ffffff 0% 0% no-repeat padding-box !important;
	opacity: 0.5 !important;
}

#home-slider .slick-dots li button {
	height: 8px !important;
	width: 30px;
	background: rgba(255, 202, 10, 0.4);
	padding: 0;
	background: #ffffff 0% 0% no-repeat padding-box;
	opacity: 0.2;
}

.slick-nav {
	color: #ffffff !important;
	--active: #ffffff !important;
}

.video-bg-style {
	background-size: contain;
	background-position: top;
	background-repeat: no-repeat;
	min-height: 20vh;
	height: 100%;
	position: relative;
	z-index: 1;
	width: 100%;
	aspect-ratio: 16/9;
}

.banner-container {
	width: 100%;
	height: auto;
}

.slider-container {
	position: relative;
}

.video-player-container {
	width: 100%;
  	height: auto;
  	overflow: hidden;
  	position: relative;
  	z-index: 1;
}
</style>
