<template>
	<img
		:src="imageSource"
		:class="imgClasses"
		:alt="altText"
		@load="handleLoad"
		@error="handleError"
		loading="lazy"
	/>
</template>

<script>
import { decode, isBlurhashValid } from "blurhash";

export default {
	name: "BlurHashImage",
	props: {
		imageUrl: String,
		blurHashString: String,
		altText: String,
		imgClasses: {
			// Accepting custom classes
			type: [String, Array, Object],
			default: "img-fluid",
		},
	},
	data() {
		return {
			currentSrc: "",
			isImageLoaded: false,
		};
	},
	methods: {
		createGradientCanvas() {
			const width = 32;
			const height = 45;
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			canvas.width = width;
			canvas.height = height;

			const gradient = ctx.createLinearGradient(0, 0, width, height);
			gradient.addColorStop(0, "white");
			gradient.addColorStop(1, "black");

			ctx.fillStyle = gradient;
			ctx.fillRect(0, 0, width, height);

			return canvas.toDataURL();
		},
		decodeBlurHashToImage(blurHashString) {
			const validation = isBlurhashValid(blurHashString);
			if (!validation.result) {
				console.error(
					"Invalid BlurHash string:",
					blurHashString,
					"Error:",
					validation.errorReason
				);
				return this.createGradientCanvas();
			}

			const width = 32;
			const height = 45;
			const pixels = decode(blurHashString, width, height);
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			canvas.width = width;
			canvas.height = height;

			const imageData = ctx.createImageData(width, height);
			imageData.data.set(pixels);
			ctx.putImageData(imageData, 0, 0);
			return canvas.toDataURL();
		},
		preloadImage() {
			if (this.imageUrl) {
				const image = new Image();
				image.src = this.imageUrl;
				image.onload = () => {
					// Once the actual image is loaded, update currentSrc
					this.currentSrc = this.imageUrl;
				};
				image.onerror = () => {
					// If actual image fails to load, emit error
					this.handleError();
				};
			}
		},

		handleLoad() {
			this.isImageLoaded = true;
			this.$emit("load");
		},
		handleError() {
			this.$emit("error");
		},
	},
	computed: {
		decodedBlurHashImage() {
			if (this.blurHashString) {
				const validation = isBlurhashValid(this.blurHashString);
				if (!validation.result) {
					console.error(
						"Invalid BlurHash string:",
						this.blurHashString,
						"Error:",
						validation.errorReason
					);
					return this.createGradientCanvas();
				}

				const width = 32;
				const height = 45;
				const pixels = decode(this.blurHashString, width, height);
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				canvas.width = width;
				canvas.height = height;

				const imageData = ctx.createImageData(width, height);
				imageData.data.set(pixels);
				ctx.putImageData(imageData, 0, 0);

				return canvas.toDataURL();
			}
			return null;
		},
		imageSource() {
			return this.currentSrc || this.decodedBlurHashImage;
		},
	},
	mounted() {
		// this.currentSrc = this.decodedBlurHashImage;
		this.preloadImage();
	},
};
</script>

<style>
.img-fluid {
	max-width: 100%;
	height: auto;
}
</style>
