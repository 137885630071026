import { API, graphqlOperation, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { searchNewsArticles } from '@/graphql/queries';
// import { getAWSStorageAsset } from '@/services/storage';

export const newsProvider = async ({ perPage, startDate, endDate, searchQuery,nextToken  }) => {
    try {
      const limit = perPage;
// Initialize filter object

let filter = {};
// filter.publishedAt = {ne:null};
if (startDate || endDate) {
  filter.publishedAt = {};

}
if (searchQuery) {
  filter['or'] = [
    { title: { match: searchQuery  } },
    { text: { match: searchQuery  } },
  ];
}
// Add date filters if available
if (startDate) {
  filter.publishedAt['gte'] = startDate;
}
if (endDate) {
  filter.publishedAt['lte'] = endDate;
}
    // Add search query filters if available
    // Fetch news data
          const newsResponse = await API.graphql({
        ...graphqlOperation(searchNewsArticles, {
          limit,
          filter,
          nextToken,
          sort: [{
            field: "publishedAt",
            direction: "desc"
          }],
          
        }),
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      });

      // Validate API response and data structure
      if (!newsResponse || !newsResponse.data || !newsResponse.data.searchNewsArticles) {
        console.error('Unexpected API response:', newsResponse);
        return [];
      }
  
      // Update nextToken for the next API call
      nextToken = newsResponse.data.searchNewsArticles.nextToken;
   
      let newsItems = newsResponse.data.searchNewsArticles.items;
      
      /*----------------------------*/
      
      /* Shifted this logic on the client side for faster loading of data */


      // newsItems = await Promise.all(
      //   newsItems.map(async (newsItem) => {
      //     const newsImage = await getAWSStorageAsset(`NewsArticle/${newsItem.id}`);
      //     newsItem.image = newsImage;
      //     return newsItem;
      //   })
      // );
      
      /*----------------------------*/

      
      return {newsItems, nextToken};
    } catch (error) {
      console.error("An error occurred while fetching news:", error);
      return [];
    }
  };


// Initialize nextToken to null
let nextTokenHighlighted = null;

export const highlightedNewsProvider = async ({ perPage }) => {
  try {
    const limit = perPage;
    // const skip = (page - 1) * perPage;

    // Fetch highlighted (pinned) news data
    const highlightedNewsResponse = await API.graphql({
      ...graphqlOperation(searchNewsArticles, {
        limit,
        // skip,
        nextToken: nextTokenHighlighted,
        sort: [{
          field: "publishedAt",
          direction: "desc"
        }],
        filter: {
          publishedAt: {
            ne: null
          },
          pinned: {
            eq: true  // Fetch only articles where pinned is true
          }
        },
      }),
      authMode: GRAPHQL_AUTH_MODE.API_KEY
    });

    // Validate the API response and data structure
    if (!highlightedNewsResponse || !highlightedNewsResponse.data || !highlightedNewsResponse.data.searchNewsArticles) {
      console.error('Unexpected API response:', highlightedNewsResponse);
      return [];
    }

    // Update nextToken for the next API call
    nextTokenHighlighted = highlightedNewsResponse.data.searchNewsArticles.nextToken;

    let highlightedNewsItems = highlightedNewsResponse.data.searchNewsArticles.items;
    // console.log(highlightedNewsItems);
    
    /*----------------------------*/
      
    /* Shifted this logic on the client side for faster loading of data */

    // highlightedNewsItems = await Promise.all(
    //   highlightedNewsItems.map(async (newsItem) => {
    //     const newsImage = await getAWSStorageAsset(`NewsArticle/${newsItem.imageFilename}`);
    //     console.log(newsItem);
    //     console.log(newsItem.imageFilename);
    //     newsItem.image = newsImage;
    //     return newsItem;
    //   })
    // );

    /*----------------------------*/

    return highlightedNewsItems;
  } catch (error) {
    console.error("An error occurred while fetching highlighted news:", error);
    return [];
  }
};