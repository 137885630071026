<template>
	<v-container>
		<!-- Highligted Articles -->
		<v-row class="mt_dec--30 align-items-center" v-if="limit === null">
			<!-- Highlighted Articles -->
			<v-col cols="7" sm="6" class="pl-0 pr-0">
				<div class="d-flex align-items-center">
					<h2 class="month-title">Highlighted Articles</h2>
					<!-- DatePicker -->
					<div class="date-picker">
						<div
							class="date-picker-icon"
							@click="toggleDatePicker('highlightedIndex')"
						>
							<img
								src="../../../../../assets/images/icons/date/white_calendar.svg"
								alt="Date Icon"
								class="datepicker-img"
							/>
						</div>
						<div
							class="date-picker-menu"
							v-click-outside="onClickOutside"
							v-show="isHighlightedDatePickerOpen"
						>
							<v-date-picker
								v-model="selectedDate"
								locale="en-in"
								dark
								type="month"
								inline
								clearable
								actions
								@click="handleDatePicker(val)"
							>
								<template v-slot:date="{ date }">
									<p>{{ date }}</p>
								</template>
							</v-date-picker>
						</div>
					</div>
				</div>
			</v-col>

			<!-- SearchBar -->
			<v-col cols="5" sm="6">
				<SearchInput
					@search-query-updated="updateSearchQuery"
					class="d-flex justify-content-end"
				/>
			</v-col>
		</v-row>

		<div>
			<!-- Highlighted Articles -->
			<ArticleGrid
				:items="topHighlightedArticles"
				:icon="nlse_icon"
				v-if="limit === null"
				class="pinned-article"
			/>

			<!-- Monthly Articles  -->
			<v-row class="mt_dec--30" v-if="limit === null">
				<v-col
					cols="12"
					v-for="(monthNews, monthIndex) in groupedNews"
					:key="monthIndex"
					class="pl-1 pr-1"
				>
					<div
						:class="{ 'news-month': limit === null }"
						v-if="monthNews.news.length > 0"
					>
						<div class="d-flex align-items-center">
							<h2 v-if="limit === null" class="month-title">
								{{ monthNews.month }}
							</h2>
							<!-- DatePicker -->
							<!-- <div class="date-picker" v-if="limit === null">
								<div
									class="date-picker-icon"
									@click="toggleDatePicker(monthIndex)"
								>
									<img
										src="../../../../../assets/images/icons/date/white_calendar.svg"
										alt="Date Icon"
										class="datepicker-img"
									/>
								</div>
								<div
									class="date-picker-menu"
									v-click-outside="onClickOutside"
									v-show="openDatePickerIndex === monthIndex"
								>
									<v-date-picker
										v-model="selectedDate"
										locale="en-in"
										dark
										type="month"
										multiple
										inline
										clearable
									>
										<template v-slot:date="{ date }">
											<p>{{ date }}</p>
										</template>
									</v-date-picker>
								</div>
							</div> -->
						</div>
						<!-- News Items -->

						<ArticleGrid :items="monthNews.news" :icon="nlse_icon" />
					</div>
				</v-col>
				<!-- News Loading -->
				<v-col
					v-if="isLoading && !isInitialLoading"
					class="justify-content-center d-flex align-items-center"
					id="load-data"
				>
					<div class="spinner-grow spinner-border"></div>
				</v-col>
				<!-- Display a message when limitedNews is empty -->
				<v-col
					v-if="
						!isInitialLoading &&
						!moreDataAvailable &&
						!isLoading &&
						!this.newsItems.length > 0
					"
					class="justify-content-center d-flex align-items-center"
				>
					<h2>Sorry, no news found.</h2>
				</v-col>
				<!-- End Blog Area  -->
			</v-row>
		</div>
		<!-- Initial Loader -->

		<div
			v-if="isInitialLoading"
			class="justify-content-center d-flex align-items-center"
		>
			<div class="spinner-grow spinner-border"></div>
		</div>
		<!-- Load More Button -->
		<div class="d-flex justify-content-center">
			<button
				v-if="
					moreDataAvailable && !isInitialLoading && !isLoading && limit === null
				"
				@click="fetchMoreNews"
			>
				<h3 class="btn-load-more">Load More</h3>
			</button>
		</div>
		<div id="load-more" ref="loadMoreRef"></div>

		<!-- Limited News for HomePage (only if limited prop is provided) -->
		<ArticleGrid :items="limitedNewsItems" :icon="nlse_icon" v-if="limit" />
	</v-container>
</template>

<script>
import SearchInput from "../Search/SearchInput.vue";
import { newsProvider } from "@/services/newsUtils/newsDataService.js";
import ArticleGrid from "./ArticleGrid.vue";
import { highlightedNewsProvider } from "@/services/newsUtils/newsDataService.js";
import moment from "moment";

export default {
	components: {
		SearchInput,
		ArticleGrid,
	},
	props: {
		limit: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			searchQuery: "",
			datePicker: false,
			// selectedDate: [],
			isDatePickerOpen: false,
			openDatePickerIndex: null,
			games: null,
			channelList: null,
			isInitialLoading: true,
			hasMoreItems: true,
			selectedDate: null,
			pinnedArticles: [],
			topHighlightedArticles: [],
			newsItems: [],
			dateMenu: false,
			menuOpen: false,
			minDate: "2023-01-05",
			maxDate: "2019-08-30",
			nlse_icon: require("@/assets/images/icons/white icons/Group 232.svg"),
			isHighlightedDatePickerOpen: false,
			isLoading: false,
			nextToken: null,
			page: 1, // new state for tracking the current page
			perPage: 12, // new state for how many items to fetch per page
			perPageMobile: 4,
			perPageHighlighted: 4,
			perPageHighlightedMobile: 2,
			moreDataAvailable: true,
			isMobile: window.innerWidth <= 760,
		};
	},
	mounted() {},
	methods: {
		handleDatePicker(val) {
			// console.log(val);
		},
		generateStartAndEndDates(month) {
			// Use Moment.js to create a moment object for the first day of the month
			// Create a moment object for the first day of the month
			const startDate = moment(month, "YYYY-MM")
				.startOf("month")
				.format("YYYY-MM-DD");

			// Create a moment object for the last day of the month
			const endDate = moment(month, "YYYY-MM")
				.endOf("month")
				.format("YYYY-MM-DD");

			// Return the generated start and end dates in an object
			return { startDate, endDate };
		},
		async fetchNews() {
			this.isLoading = true;
			const existingIds = new Set(this.newsItems.map((item) => item.id)); // Create a Set of existing IDs

			let perPage = this.isMobile ? this.perPageMobile : this.perPage;
			// Initialize an options object with values that are always needed
			let options = {
				perPage: perPage,
				searchQuery: this.searchQuery,
			};

			// If a date is selected, add startDate and endDate to the options
			if (this.selectedDate) {
				const { startDate, endDate } = this.generateStartAndEndDates(
					this.selectedDate
				);
				options = { ...options, startDate, endDate };
			}

			// Fetch news items and nextToken
			const { newsItems, nextToken } = await newsProvider({
				...options,
				nextToken: this.nextToken,
			});

			// Conditions for loading
			this.nextToken = nextToken;
			// console.log(newsItems.length);
			if (newsItems.length < perPage) {
				this.moreDataAvailable = false;
			} else {
				this.moreDataAvailable = true;
			}
			const uniqueNewsItems = newsItems.filter(
				(item) => !existingIds.has(item.id)
			); // Filter out duplicates

			if (uniqueNewsItems.length > 0) {
				this.newsItems = [...this.newsItems, ...uniqueNewsItems];

				this.newsItems = this.newsItems.sort((a, b) => {
					return new Date(b.publishedAt) - new Date(a.publishedAt);
				});
			}
			this.isLoading = false;
			this.isInitialLoading = false;
			// console.log(this.moreDataAvailable);
		},

		async fetchTopPinnedArticles() {
			let perPageHighlighted = this.isMobile
				? this.perPageHighlightedMobile
				: this.perPageHighlighted;

			const topHighlighted = await highlightedNewsProvider({
				perPage: perPageHighlighted, // Fetch top 4 highlighted articles
			});

			this.topHighlightedArticles = topHighlighted;
		},

		async fetchMoreNews() {
			if (this.isLoading) return;
			this.isLoading = true;
			await this.fetchNews();
			this.isLoading = false;
		},

		pushArticle() {
			$router.push({ name: "article", params: { id: newsItem.id } });
		},
		onClickOutside() {
			// this.$set(this.isDatePickerOpen, index, false);
			this.openDatePickerIndex = null;
			this.isHighlightedDatePickerOpen = false;
		},
		updateSearchQuery(query) {
			this.searchQuery = query;
			this.newsItems = []; // Clear current news items
			this.nextToken = null;
			this.fetchNews(); // Fetch new items based on search
		},
		toggleDatePicker(index) {
			if (index === "highlightedIndex") {
				if (!this.isHighlightedDatePickerOpen) {
					setTimeout(() => {
						this.isHighlightedDatePickerOpen =
							!this.isHighlightedDatePickerOpen;
					}, 100);
				} else {
					this.isHighlightedDatePickerOpen = false;
				}
			} else {
				if (this.openDatePickerIndex === index) {
					setTimeout(() => {
						this.openDatePickerIndex = null;
					}, 100);
				} else {
					setTimeout(() => {
						this.openDatePickerIndex = index;
					}, 100);
				}
			}
		},

		formatDate(date) {
			// Implement your custom date formatting logic here
			return date.toLocaleDateString();
		},
		// For single selection

		isDateMatch(newsDate, selectedDate) {
			const itemDate = new Date(newsDate);
			const [selectedYear, selectedMonth] = selectedDate.split("-");
			const itemYear = itemDate.getFullYear();
			const itemMonth = itemDate.getMonth() + 1; // Month is zero-based, so add 1 to get the actual month

			return (
				itemYear === Number(selectedYear) && itemMonth === Number(selectedMonth)
			);
		},
	},

	computed: {
		groupedNews() {
			const newsByMonthYear = {};
			for (const newsItem of this.newsItems) {
				let date;
				// We are making sure if publishedAt is not available then switch to createdAt which is always available by default from AWS.
				if (newsItem.publishedAt) {
					date = new Date(newsItem.publishedAt);
				} else {
					date = new Date(newsItem.createdAt);
				}
				const month = date.toLocaleString("default", { month: "long" });
				const year = date.getFullYear();
				const monthYearKey = `${month} ${year}`;

				if (!newsByMonthYear[monthYearKey]) {
					newsByMonthYear[monthYearKey] = [];
				}

				newsByMonthYear[monthYearKey].push(newsItem);
			}

			const groupedNews = [];

			for (const [monthYear, news] of Object.entries(newsByMonthYear)) {
				const [month, year] = monthYear.split(" ");
				groupedNews.push({
					month: `${month} ${year}`,
					news,
				});
			}
			// console.log(groupedNews);
			return groupedNews;
		},

		filteredGroupedNews() {
			if (
				(!this.searchQuery || this.searchQuery.trim() === "") &&
				this.selectedDate.length === 0
			) {
				return this.groupedNews;
			}
			const searchQueryLower = String(this.searchQuery).toLowerCase().trim();

			return this.groupedNews.map((group) => {
				const filteredItems = group.news.filter((newsItem) => {
					// skip null or incomplete news items
					if (
						!newsItem ||
						!newsItem.title ||
						!newsItem.updatedAt ||
						!newsItem.text
					)
						return false;

					const titleLower = String(newsItem.title).toLowerCase();
					const summaryLower = String(newsItem.text).toLowerCase();
					const titleMatch = titleLower.includes(searchQueryLower);
					const summaryMatch = summaryLower.includes(searchQueryLower);
					const dateMatch =
						this.selectedDate.length === 0 ||
						this.isDateMatch(newsItem.updatedAt, this.selectedDate);

					return (titleMatch || summaryMatch) && dateMatch;
				});

				return {
					month: group.month,
					news: filteredItems,
				};
			});
		},

		limitedNewsItems() {
			return this.limit ? this.newsItems.slice(0, this.limit) : this.newsItems;
		},
	},
	watch: {
		selectedDate: {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.page = 1; // Reset to first page
					this.newsItems = []; // Clear the news items
					this.nextToken = null;
					this.isLoading = true;
					this.fetchNews(); // Fetch news with the new selected date
				}
			},
			deep: true, // Watch object properties inside array for changes
		},
	},
	async mounted() {
		// const allNewsItems = await newsProvider();
		await this.fetchTopPinnedArticles();

		await this.fetchNews();

		this.isInitialLoading = false;
		if (this.newsItems.length > 0) {
			this.$emit("newsDataAvailable", true); // Emit true, indicating data is available

			this.$emit("isLoading", true);
		} else {
			this.$emit("newsDataAvailable", false); // Emit false, indicating no data is available

			this.$emit("isLoading", false);
		}
	},
};
</script>

<style scoped>
/* Import OSWALD Fonts Link */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
p {
	text-align: left;
	font: normal normal bold 30px/45px Oswald;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}

.heading {
	text-align: center;
	/* font: normal normal bold 80px/118px Oswald; */
	letter-spacing: 0px;
	color: #ffffff !important;
	opacity: 1;
	font-weight: bold;
	font-size: 24px;
	line-height: normal !important;
	font-family: Oswald;
}

.v-application a {
	color: #ffffff !important;
}

.thumbnail {
	width: 100%;
	height: auto;
	border-radius: 0px;
}

.im_box {
	border-radius: 0px !important;
}

.news-month {
	margin-bottom: 20px;
}

.month-title {
	/* font-size: 2.4rem; */
	font-size: clamp(0.9rem, 2vw, 2.5rem);
	font-weight: bold;
	margin-bottom: 10px;
	font-family: sans-serif;
}

.news-items {
	display: flex;
	flex-wrap: wrap;
}

/* DatePicker */

.date-picker {
	position: relative;
	padding-left: 10px;
}

.date-picker-icon {
	cursor: pointer;
}

.date-picker-menu {
	position: absolute;
	z-index: 1000;
	border-radius: 4px;
	padding: 8px;
}

.datepicker-img {
	width: 50px;
}

@media (max-width: 800px) {
	.v-picker__body {
		width: 40vw !important;
		max-width: none !important;
		position: fixed !important;
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	}

	.v-picker {
		max-width: none !important;
		position: fixed !important;
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
}

.spinner-loader {
	border: 8px solid rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	border-top: 8px solid blue;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.btn-load-more {
	margin: 1.2rem 1rem;
	font-family: Verdana, Geneva, Tahoma, sans-serif !important;
	font-weight: 600;
	transition: color 0.5s ease;
}

.btn-load-more:hover {
	color: var(--iq-primary);
}

@media (max-width: 768px) {
	.pinned-article:nth-child(n + 3) {
		display: none;
	}
}
</style>
