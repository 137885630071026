import { API, graphqlOperation, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { tvScheduledEventsByDate } from '@/graphql/queries';

/**
 * Fetches schedule items from the GraphQL API.
 * 
 * This function queries the GraphQL endpoint to retrieve TV scheduled events
 * that are set to occur today or in the future, up to a maximum of 1000 items.
 * It uses an API key for authorization.
 *
 * @return {Promise<Array>} A promise that resolves to an array of scheduled items.
 */
export const fetchScheduleItems = async () => {
    try {
        const todayDateOnly = new Date().toISOString().split('T')[0];
        const response = await API.graphql({
            query: tvScheduledEventsByDate,
            variables: {
                version:1,
                sortDirection: 'DESC',
                limit: 1000,
                filter: {
                    filterDateTime: {
                        // ('ge' means 'greater than or equal to')
                        ge: todayDateOnly
                    },
                },

            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        let Scheduleitems = response.data.tvScheduledEventsByDate.items;
        return Scheduleitems;
    } catch (error) {
        console.error("An error occurred while fetching scheduled items:", error);
        return [];
    }
};
