<template>
	<div class="container bg-transparent-black mt--20">
		<div class="fixed-container">
			<h1 class="title--header">SCHEDULE</h1>
			<b-row v-if="scheduleData.length > 0" class="options-container">
				<b-col
					offset-md="4"
					cols="8"
					md="4"
					class="d-flex justify-content-center align-items-center"
					mx-auto
				>
					<div class="custom-select-container">
						<div class="date-navigator">
							<button
								@click="selectPreviousDate"
								class="arrow-button"
								:disabled="isFirstDateSelected"
								aria-label="Previous day"
							>
								<img
									src="@/assets/images/icons/NLSE.com What's On – 2/Path 2699.svg"
									alt="Previous day"
								/>
							</button>
							<transition name="fade" mode="out-in">
								<span
									:key="selectedDate"
									class="date-display"
									aria-live="assertive"
									>{{ getFormattedDate(selectedDate) }}</span
								>
							</transition>
							<button
								@click="selectNextDate"
								class="arrow-button"
								:disabled="isLastDateSelected"
								aria-label="Next day"
							>
								<img
									src="@/assets/images/icons/NLSE.com What's On – 2/Path 2698.svg"
									alt="Previous day"
								/>
							</button>
						</div>
					</div>
				</b-col>

				<b-col cols="4" class="d-flex justify-content-end align-items-center">
					<div class="search-bar">
						<input
							type="text"
							v-model="searchQuery"
							class="search-bar__input"
							aria-label="search"
							placeholder="Search Schedule"
							@input="filterShows"
							ref="searchInput"
						/>
						<button class="search-bar__submit" @click="focusInput">
							<i class="fa fa-search" aria-hidden="true"></i>
						</button>
					</div>
				</b-col>
			</b-row>
			<div class="table-container">
				<div v-if="isLoading" class="loading-message">
					Loading shows, please wait...
				</div>
				<table v-else class="table">
					<tbody v-for="(show, index) in displayedShows" :key="show.id">
						<!-- ------------ table row start --------------- -->

						<tr
							:key="index"
							:class="{
								'bg-black': index % 2 === 0,
								'bg-gray': index % 2 !== 0,
								highlighted: isLive(show, index),
							}"
							class="schedule-tbody"
						>
							<td style="width: 150px">
								<div class="text-center align-center">
									<div class="mb-0 ml-0 d-inline">
										<span
											v-if="isLive(show, index)"
											class="live-now-dot d-inline-block"
										></span>
										<p class="mt-0 d-inline p0">
											{{ getFormattedTime(show.time) }}
										</p>
									</div>
								</div>
								<!-- Live now button -->
								<!-- <div
                                    class="mb-0 ml-0"
                                    style="
                                        padding-left: -14px;
                                        position: relative;
                                        right: 11px;
                                        bottom: -12px;
                                    "
                                >
                                    <button v-if="isLive(show, index)" class="live-now-cell">
                                        LIVE NOW
                                    </button>
                                </div> -->
							</td>
							<td>
								<p class="mb-0 mt-0 align-center">
									<!-- expandable -->
									<!-- @click="toggleShowDetails(show)" -->

									{{ show.title }}
								</p>
							</td>
							<td>
								<p class="mb-0 schedule-description">
									{{ show.description }}
								</p>
							</td>
						</tr>

						<!-- ------------ Extended Table row for more info on click --------------- -->
						<!-- <transition name="slide-fade">
							<tr
								v-if="expandedShowId === show.id"
								:class="{
									'bg-black': index % 2 === 0,
									'bg-gray': index % 2 !== 0,
									highlighted: isLive(show, index),
								}"
							>
								<td></td>
								<td colspan="100%" style="padding: 0px">
									<div class="show-details">
										{{ show.description }}
										<p>
											The Canadian Elite Basketball League is a men's
											professional minor league basketball organization. The
											CEBL was founded in 2017 and began play in 2019 with six
											teams competing all owned and operated by ownership group
											Canadian Basketball Ventures.
										</p>

										<button class="content-button mb-3">
											<a href="https://plus.nlse.com" target="_blank">
												Find more content on NLSE+
											</a>
										</button>
									</div>
								</td>
							</tr>
						</transition> -->
					</tbody>
					<tbody v-if="displayedShows.length < 1">
						<tr>
							<td colspan="3" class="text-center no-show-available">
								No shows found.
							</td>
						</tr>
					</tbody>
				</table>
				<div class="table-navigation"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchScheduleItems } from "@/services/schedule";
import moment from "moment-timezone";
import { getFormattedTime, getFormattedDate } from "@/services/dateUtils/date";

export default {
	name: "ScheduleList",
	data() {
		return {
			allShows: [],
			// selectedDate: moment().format("YYYY-MM-DD"),
			selectedDate: null,
			userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Set user's timezone
			displayedShows: [],
			scheduleList: new Map(),
			scheduleData: [],
			isLoading: true,
			searchQuery: "",
			expandedShowId: null,
			isLiveEvent: false,
		};
	},
	methods: {
		filterShows() {
			this.displayedShows = this.scheduleData.filter((show) => {
				const matchesSearchQuery =
					show.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
					show.description
						.toLowerCase()
						.includes(this.searchQuery.toLowerCase());

				const matchesSelectedDate = this.selectedDate
					? show.date === this.selectedDate
					: true;

				return matchesSearchQuery && matchesSelectedDate;
			});
		},
		async fetchAndInitialize() {
			try {
				const fetchedItems = await fetchScheduleItems();
				this.scheduleData = fetchedItems
					.map((item) => ({
						...item,
						time: moment.utc(item.dateTime).tz(this.userTimezone).format(),
						date: moment
							.utc(item.dateTime)
							.tz(this.userTimezone)
							.format("YYYY-MM-DD"),
					}))
					.sort((a, b) => {
						// Compare by date first
						if (a.date < b.date) return -1;
						if (a.date > b.date) return 1;

						// If dates are the same, compare by time
						if (a.time < b.time) return -1;
						if (a.time > b.time) return 1;

						return 0;
					});
				this.selectedDate = this.getEarliestDate();
				this.filterShowsByDate();
				this.isLoading = false;
			} catch (error) {
				this.isLoading = false;
				console.error("An error occurred:", error);
			}
		},
		// Change show.desc to the value as per the new CMS
		toggleShowDetails(show) {
			// if (show.description && show.description.trim().length > 0) {
			this.expandedShowId = this.expandedShowId === show.id ? null : show.id;
			// }
		},
		// Function to update shows based on the selected date
		filterShowsByDate() {
			this.displayedShows = this.scheduleData.filter(
				(show) => show.date === this.selectedDate
			);
		},
		focusInput() {
			this.$refs.searchInput.focus();
		},
		getEarliestDate() {
			const dates = this.scheduleData.map((item) => item.date);
			return dates.length ? dates.sort()[0] : null;
		},
		getFormattedTime(time) {
			return getFormattedTime(time, this.userTimezone);
		},
		getFormattedDate(date) {
			return getFormattedDate(date);
		},
		selectPreviousDate() {
			const currentIndex = this.dates.indexOf(this.selectedDate);
			if (currentIndex > 0) {
				this.selectedDate = this.dates[currentIndex - 1];
				this.filterShows();
			}
		},

		selectNextDate() {
			const currentIndex = this.dates.indexOf(this.selectedDate);
			if (currentIndex < this.dates.length - 1) {
				this.selectedDate = this.dates[currentIndex + 1];
				this.filterShows();
			}
		},
		isLive(show, index) {
			const now = moment().tz(this.userTimezone);
			const showStartTime = moment(show.time).tz(this.userTimezone);

			let showEndTime;

			if (index < this.displayedShows.length - 1) {
				// If there is a next show, use its start time as the end time of the current show
				showEndTime = moment(this.displayedShows[index + 1].time).tz(
					this.userTimezone
				);
			} else {
				// If this is the last show, you might want to set a default duration or end the day
				showEndTime = showStartTime.clone().endOf("day"); // Example: End of the day
			}
			const isLiveEvent = now.isBetween(showStartTime, showEndTime);
			this.isLiveEvent = isLiveEvent;
			return isLiveEvent;
		},
	},
	computed: {
		dates() {
			const uniqueDates = Array.from(
				new Set(this.scheduleData.map((item) => item.date))
			);
			return uniqueDates.sort();
		},
		isFirstDateSelected() {
			return this.selectedDate === this.dates[0];
		},

		isLastDateSelected() {
			return this.selectedDate === this.dates[this.dates.length - 1];
		},
	},
	created() {
		this.fetchAndInitialize();
		this.interval = setInterval(() => {
			this.filterShows(); // Or any method that refreshes the displayed shows
		}, 60000); // Update every minute, adjust as needed
	},
	beforeDestroy() {
		clearInterval(this.interval); // Clear the interval when the component is destroyed
	},
};
</script>

<style scoped lang="scss">
.no-show-available {
	font-size: 1.2rem;
}
.bg-black {
	background-color: #1d1d1d;
}

.bg-gray {
	background-color: #232323;
}

.bg-transparent-black {
	background-color: #040505ad;
}

.custom-select {
	max-width: 290px;
	/* background-color: transparent; */
	border: none;
	box-shadow: none;
	appearance: none;
	text-align: left;
	letter-spacing: 0px;
	color: #040505;
	font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
		sans-serif;
	font-weight: 800;
}

.custom-select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: none !important;
	content: "\25BC"; /* Unicode down arrow */
}

.custom-select:after {
	content: "X"; /* Unicode down arrow */
	font-size: 12px;
	padding-right: 8px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}
.expandable {
	cursor: pointer;
}
.custom-select-container {
	position: relative;
}

.custom-arrow {
	position: absolute;
	top: 45%;
	right: 10px;
	font-size: 20px;
	transform: translateY(-50%);
	pointer-events: none;
}
.is-transparent {
	text-shadow: 0 0 0 #000;
	background-color: transparent;
	color: #bbbbbb !important;
	font-weight: 100 !important;
}

.btn-transparent-yellow {
	color: var(--primary);
	letter-spacing: 0px;
	font-size: 1rem;
	font-weight: bold;
}

.btn-transparent-disabled {
	color: var(--gray);
	letter-spacing: 0px;
	font-size: 1rem;
	font-weight: bold;
}
.schedule-header {
	margin-bottom: 20px;
	text-align: left;
	letter-spacing: 0px;
	color: #bbbbbb;
	text-shadow: 0px 8px 8px #00000096;
	text-transform: uppercase;
	opacity: 1;
	font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
		sans-serif;
	font-weight: 800;
}

.table-container {
	margin-top: 80px;
	max-height: 400px; /* Adjust this value based on your design needs */
	overflow-y: auto; /* Enables vertical scrolling */
}

.table td,
.table th {
	vertical-align: middle;
}

.schedule-description {
	letter-spacing: var(--unnamed-character-spacing-0);
	text-align: left;
	font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
		sans-serif;
	letter-spacing: 0px;
	color: #bbbbbb;
	opacity: 1;
	font-size: 1.2rem;
}
.content-button {
	background-color: var(--primary);
	border-radius: 5px;
	opacity: 1;
	align-items: center;
	align-content: center;
	padding: 0.2rem;
	font-size: 1rem;
	color: var(--dark);
	// font-family: Roboto bold !important;
	padding: 1px 5px;
	color: black;
}

.content-button a {
	color: black;
	text-decoration: none;
}
.content-button a:hover {
	color: #ffffff;
	text-decoration: none;
}
.loading-message {
	text-align: center;
	color: #fff;
	margin-top: 20px;
}

.no-show-available {
	font-size: 1.2rem;
	color: #bbb;
}

.date-navigator {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 1.5rem; /* Adjust the size as needed */
}

.arrow-button {
	background: none;
	border: none;
	color: inherit;
	font-size: inherit;
	cursor: pointer;
	padding: 0 20px; /* Space around the arrows */
}

.arrow-button:disabled {
	cursor: default;
	opacity: 0.5;
}

.date-display {
	margin: 0 10px;
	font-weight: bold;
	font: normal normal medium 20px/60px Rival Sans;
	color: #ffffff;
	font-size: 1.2rem; /* Adjust the size as needed */
	white-space: nowrap;
}

/* Transition effect on date */

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
	transition: all 0.3s ease;
}

// Transition effect on Expanded Table Data
.slide-fade-enter, .slide-fade-leave-to /* Starting state for enter/leave transitions */ {
	opacity: 0;
	transform: translateY(-20px);
}
.arrow-button img {
	width: 30px;
	height: 30px;
}
/* Search Input */
.search-bar {
	border-bottom: 1px solid #707070; /* Bottom border only, set to white */
	align-items: center;
	display: flex;
	padding: 0;
	background-color: transparent; /* Transparent background */
	height: 3rem; /* Height of the search bar */
	width: 100%; /* Full width of the container */
	position: relative;
}
.highlighted {
	border-left: 1px solid var(--primary) !important;
	border-right: 1px solid var(--primary) !important;
	font-weight: bold !important;
}

.search-bar__input {
	flex-grow: 1;
	font-size: 1rem;
	padding: 0 2.5rem 0 1rem; /* Padding for text and icon */
	border: 0;
	background: transparent;
	color: #ffffff; /* Text color, set to white */
	line-height: 1; /* Adjust line height as needed */
	font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
		sans-serif;
	&:focus {
		outline: none; /* Remove outline on focus */
		background-color: rgba(
			255,
			255,
			255,
			0.1
		); /* Optional background on focus */
	}
}
// Search Bar CSS
.search-bar__submit {
	position: absolute;
	right: 0; /* Align to the right */
	top: 50%;
	transform: translateY(-50%); /* Center vertically */
	font-size: 1.2rem;
	color: #ffffff;
	cursor: none;
	border: none;
	background: none;
	padding: 0 1rem; /* Padding for clickable area */
	line-height: 1;
}
.schedule-tbody {
	height: 6rem;
	font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
		sans-serif !important;
	font-size: 1.2rem;
}
// Live Now Event
.live-now-cell {
	background-color: var(--primary);
	color: black;
	font-weight: bold;
	padding: 0.5rem;
	text-align: center;
	font-family: Roboto Bold;
	height: 38px;
	width: 84px;
	font-size: 0.7rem;
	cursor: pointer;
}

.show-details {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
		sans-serif;
	font-size: 1.2rem;
}

.live-now-dot {
	width: 13px; /* Size of the dot */
	height: 13px; /* Size of the dot */
	border-radius: 50%; /* Makes it circular */
	background-color: var(--red); /* The color of the dot */
	position: relative;
	display: inline-block;
	animation: pulse 1s infinite ease-in-out;
	margin-right: 10px;
}

/* Keyframes for the pulsating effect */
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
	}
	70% {
		box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
	}
}

.table tr,
.table td {
	border: none;
}
.table tbody + tbody {
	border: none !important;
}

.ellipsis {
	max-width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
