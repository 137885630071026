import televisionProviderInformation from "../StaticData/televisionProviderInformation.json";

/**
 * This function is helper function that provides alphabetical sorting of channel providers.
 * 
 */
const sortedTelevisionProviderInformation = (channelProviders) => {
    return channelProviders.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};

/**
 * This function provides channels with images only.
 * 
 */
// export const getTelevisionProviderInformationWithImages = () => {
//     return sortedTelevisionProviderInformation( getTelevisionProviderInformation().filter(({ image }) => image !== undefined) );
// };
export const getTelevisionProviderInformationWithImages = () => {
    // Step 1: Filter out the objects that don't have images
    const filteredByImage = [...televisionProviderInformation].filter(({ image }) => image !== undefined);
    // console.log(sortedTelevisionProviderInformationByOrder(filteredByImage));
    // Step 2: Sort the filtered array by 'order'
    return sortedTelevisionProviderInformationByOrder(filteredByImage);
};


/**
 * This function provides all channels.
 * 
 */
export const getTelevisionProviderInformation = () => {
    return sortedTelevisionProviderInformation([ ...televisionProviderInformation ]);
};
/*
    This function provides all info with sorted respect to Order attribute
*/
const sortedTelevisionProviderInformationByOrder = (tvProviders) => {
    return tvProviders.sort((a, b) => {
      // Handle cases where "order" field is missing
      const orderA = a.order || Number.MAX_SAFE_INTEGER;
      const orderB = b.order || Number.MAX_SAFE_INTEGER;
        
      return orderA - orderB;
    });
  };