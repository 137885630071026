<template>    
    <div class="mute-button-container" @click="toggleMute">
        <button class="mute-button">
            <div v-if="mute" class="sr-only">Mute</div>
            <svg 
                v-if="mute"
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 512 512"
                fill="white"
                >
                <path d="M215 71.1L126.1 160H24c-13.3 0-24 10.7-24 24v144c0 13.3 10.7 24 24 24h102.1l89 89c15 15 41 4.5 41-17V88c0-21.5-26-32-41-17zM461.6 256l45.6-45.6c6.3-6.3 6.3-16.5 0-22.8l-22.8-22.8c-6.3-6.3-16.5-6.3-22.8 0L416 210.4l-45.6-45.6c-6.3-6.3-16.5-6.3-22.8 0l-22.8 22.8c-6.3 6.3-6.3 16.5 0 22.8L370.4 256l-45.6 45.6c-6.3 6.3-6.3 16.5 0 22.8l22.8 22.8c6.3 6.3 16.5 6.3 22.8 0L416 301.6l45.6 45.6c6.3 6.3 16.5 6.3 22.8 0l22.8-22.8c6.3-6.3 6.3-16.5 0-22.8L461.6 256z"/>
            </svg>
        
            <div v-if="!mute" class="sr-only">Unmute</div>
            <svg 
                v-if="!mute"
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 640 512"
                fill="white"
                >
                <path d="M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"/>
            </svg>
        </button>     
    </div>
</template>
    
<script>

export default {
    name: "Home",
	props: ["index"],
    data() {
    return {
        mute: true,
        buttonIndex: null,
        }
    },
    methods: {
        toggleMute(e) {
            e.preventDefault();
            this.mute = !this.mute
            this.$emit('is-muted', this.mute)
            this.$emit('mute-button-index', this.buttonIndex)
        },
    },
    mounted(){
        this.buttonIndex = this.index;
        //Make sure the button is mute by default
        this.mute = true;
    }
}
</script>
<style scoped>
.mute-button-container {
    display: flex;
}
.mute-button{
    position: absolute;
    flex: 1;
	z-index: 999;
  	bottom: 2.5em; 
  	left: 1.2em; 
  	margin: 0 auto;
	height: 2em;
	width: 2em;
    opacity: 0.7;
    /* border: 1px lightslategray solid; */
    border-radius: 50%;
    padding: 3px;
}
svg {
    width: 100%;
    height: 100%;
    object-fit: fill;  
    padding-bottom: 0.2em;  
}

@media screen and (max-width: 480px){
    .mute-button {
        height: 1.7em;
	    width: 1.7em;
    }
    svg{
        bottom: 2.8em; 
  	    left: 0.2em; 

    }
}

</style>