<template>
	<v-row class="news-items">
		<v-col
			sm="6"
			md="4"
			lg="3"
			cols="6"
			v-for="item in items"
			:key="item.id"
			class="px-1"
		>
			<news-card
				:image="
					item.imageFilename && item.imageFilename.length > 0
						? item.imageFilename[0]
						: ''
				"
				:title="item.title"
				:icon="icon"
				:link="item.id"
				:blurHashString="item.blurHash"
			/>
		</v-col>
	</v-row>
</template>
<script>
import NewsCard from "./NewsCard.vue";

export default {
	components: {
		NewsCard,
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		icon: {
			type: String,
			default: "",
		},
	},
};
</script>
